import {
  CreateOutlined,
  LibraryBooksOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';

import { HeadwayMark } from '@headway/icons/dist/HeadwayMark';

import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';
import { DropdownTitle } from './components/DropdownTitle';

const a11yHeadwayMark = () => {
  return (
    <HeadwayMark aria-hidden="true" focusable="false" width={20} height={20} />
  );
};

const CompanyDropdown = () => {
  return (
    <div>
      <DropdownSection data-first-dropdown-section>
        <DropdownList>
          <DropdownListLink href="/about-us" icon={a11yHeadwayMark}>
            About us
          </DropdownListLink>
          <DropdownListLink href="/blog" icon={CreateOutlined}>
            Blog
          </DropdownListLink>
          <DropdownListLink href="/press" icon={LibraryBooksOutlined}>
            Press
          </DropdownListLink>
          <DropdownListLink href="/careers" icon={WorkOutlineOutlined}>
            Careers
          </DropdownListLink>
        </DropdownList>
      </DropdownSection>
      <DropdownSection>
        <DropdownTitle>From The Blog</DropdownTitle>
        <DropdownList>
          <DropdownListLink href="/blog/headway-milestone">
            A milestone at Headway
          </DropdownListLink>
        </DropdownList>
      </DropdownSection>
    </div>
  );
};

export default CompanyDropdown;
