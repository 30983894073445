import {
  AttachMoneyTwoTone,
  HelpOutlineTwoTone,
  SearchTwoTone,
} from '@mui/icons-material';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useMarkets } from '@headway/ui/providers/MarketProvider';

import { IAuthStore, withStores } from '../../../stores/withStores';
import { getProvidersSearchPath } from '../../../utils/search';
import {
  LandingPageInteraction,
  SearchEntry,
  trackProviderSearchCompleted,
  trackProviderSearchStarted,
} from '../../../utils/searchAnalyticEvents';
import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';
import { DropdownTitle } from './components/DropdownTitle';

type Props = {
  AuthStore: IAuthStore;
};

const GetCareDropdownImpl: React.FC<Props> = ({ AuthStore }) => {
  const { liveMarkets } = useMarkets();
  const {data: shouldShowAnthemEAPExperience} = useShouldShowAnthemEAPExperience(
    AuthStore?.user?.id,
    undefined,
    undefined
  );

  return (
    <div css={{ maxHeight: '80vh' }}>
      {liveMarkets?.length > 1 && (
        <DropdownSection
          data-first-dropdown-section
          css={{
            maxHeight: '40vh',
            overflow: 'auto',
          }}
        >
          <DropdownTitle>Find a therapist</DropdownTitle>
          <DropdownList>
            {sortBy(liveMarkets, 'displayName').map((market) => (
              <div
                key={market.id}
                onClick={() => {
                  trackProviderSearchStarted({
                    context: {
                      interaction: LandingPageInteraction.Location,
                      source: SearchEntry.GetCareHeader,
                    },
                    filters: {
                      state: market.state,
                    },
                  });
                  trackProviderSearchCompleted({
                    context: {
                      source: SearchEntry.GetCareHeader,
                    },
                    filters: {
                      state: market.state,
                    },
                  });
                }}
              >
                <DropdownListLink
                  key={market.slug}
                  href={getProvidersSearchPath({ marketSlug: market.slug })}
                >
                  {market.displayName}
                </DropdownListLink>
              </div>
            ))}
          </DropdownList>
        </DropdownSection>
      )}
      {liveMarkets?.length < 2 && !shouldShowAnthemEAPExperience && (
        <>
          <DropdownSection
            data-first-dropdown-section={
              !liveMarkets.length || !(liveMarkets.length < 2)
            }
          >
            <DropdownList>
              {liveMarkets?.length < 2 && (
                <DropdownListLink
                  href={getProvidersSearchPath()}
                  icon={SearchTwoTone}
                >
                  Find a therapist
                </DropdownListLink>
              )}
                <DropdownListLink
                  href="/#how-it-works"
                  icon={HelpOutlineTwoTone}
                >
                  How it works
                </DropdownListLink>
                <DropdownListLink
                  href="/does-my-insurance-cover-therapy"
                  icon={AttachMoneyTwoTone}
                >
                  Does my insurance cover therapy?
                </DropdownListLink>
            </DropdownList>
          </DropdownSection>
        </>
      )}
    </div>
  );
};

const GetCareDropdown = withStores(GetCareDropdownImpl);

export default GetCareDropdown;
